
<script>
import { Line } from "vue-chartjs";
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from "@/constants/AppConst";


var chartColors = {
  color1: APP_CONST.STYLE.COLOR.BLUE_700,
  color2: APP_CONST.STYLE.COLOR.NEUTRAL_100,
};
let curr = new Date();
let currentWeeek = "";
let weekdays = [];
for (let i = 1; i <= 7; i++) {
  let first = curr.getDate() - curr.getDay() + i;
  let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
  weekdays.push(day);
}

export default {
  extends: Line,
  name: "LineChart",
  props: ["data", "endDate", "printChart", "isMobileWidth", "hideQualityAnnotation"],
  mounted() {
    let chartData = {
      labels: [],
      datasets: [
        {
          backgroundColor: APP_CONST.COLOR.PRIMARY_700,
          borderColor: APP_CONST.COLOR.PRIMARY_700,
          borderWidth: 1,
          lineTension: 0.4,
          data: [],
          fill: false,
          lineTension: 0,
          pointBackgroundColor: ["", ""],
          pointRadius: [],
          pointHoverRadius: []
        },
      ],
      hideQualityAnnotation: this.hideQualityAnnotation,
      printChartStatus: this.printChart,
      isMobileWidth: this.isMobileWidth,
    };

    let gridLineColor = [];
    gridLineColor.push("black");
    for (let i = 0; i < this.data.length; i++) {
      chartData.labels.push(this.data[i].week);
      if (this.data[i].score != "N/A") {
        chartData.datasets[0].pointRadius.push(this.data[i].fake ? 0 : 4);
        chartData.datasets[0].pointHoverRadius.push(this.data[i].fake ? 0 : 5);
        chartData.datasets[0].data.push({
          x: this.data[i].week,
          y: this.data[i].score,
          date: this.data[i].endDate,
          fakeValue: this.data[i].fake
        });
      }
      if (weekdays.indexOf(this.endDate) > -1 && i == this.data.length - 2) {
        chartData.datasets[0].pointBackgroundColor[i] = chartColors.color2;

      } else {
        chartData.datasets[0].pointBackgroundColor[i] = chartColors.color1;

      }
      if (i > this.data.length - 3) {
        gridLineColor.push(APP_CONST.STYLE.COLOR.NEUTRAL_100);
      } else {
        gridLineColor.push(APP_CONST.STYLE.COLOR.NEUTRAL_600);
      }
    }
    let options = {
      tooltips: {
        mode: "single",
        enabled: false,
        callbacks: {
          label: (tooltipItem, chart) => {
            const realValue =
              chart.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return [realValue.y + "</br>", realValue.date];
          },
        },
        custom: function (tooltipModel) {
          var tooltipEl = document.getElementById("chartjs-tooltip");

          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          function formattedDate(date) {
            return date ? APP_UTILITIES.formatDate(date, true, true) : '';
          }

          if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];

            var bodyLines = tooltipModel.body.map(getBody);
            var innerHtml =
              `<div style="width:170px;height: 61px;margin: -90px 51px 25px -55px;box-shadow: ${APP_CONST.STYLE.BOX_SHADOW.ELEVATION_100};background-color:${APP_CONST.STYLE.COLOR.NEUTRAL_100};padding: 10px 13px;">`;

            titleLines.forEach(function () {
              var style = "width: 50px";
              style += "; height: 25px";
              style += ";position: absolute";
              style += ";top: 100%";
              style += ";left: 0";
              style += ";transform: translateX(-50%)";
              style += ";overflow: hidden";
              style += ";margin-top: -36px";
              var span = '<div class="tooltip__arrow" style="' + style + '">';
              innerHtml += span + "</div>";
            });

            titleLines.forEach(function (title) {
              currentWeeek = title;
              var style = `font-size: ${APP_CONST.STYLE.FONT_SIZE.PARAGRAPH_300_HEAVY_SIZE}`;
              style += `; font-weight: ${APP_CONST.STYLE.FONT_WEIGHT.PARAGRAPH_300_HEAVY_WEIGHT}`;
              style += `;font-family: ${APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT}`;
              style += `;color: ${APP_CONST.STYLE.COLOR.NEUTRAL_900}`;
              style += `;line-height: ${APP_CONST.STYLE.LINE_HEIGHT.PARAGRAPH_300_HEAVY_LINE_HEIGHT}`;
              var span = '<span style="' + style + '">';
              innerHtml += span + "Score" + ":</span>";
            });

            bodyLines.forEach(function (body, i) {
              var style = `font-size: ${APP_CONST.STYLE.FONT_SIZE.PARAGRAPH_300_LIGHT_SIZE}`;
              style += `;font-family: ${APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT}`;
              style += `;color: ${APP_CONST.STYLE.COLOR.NEUTRAL_900}`;
              style += `;line-height: ${APP_CONST.STYLE.LINE_HEIGHT.PARAGRAPH_300_LIGHT_LINE_HEIGHT}`;
              style += `;font-weight: ${APP_CONST.STYLE.FONT_WEIGHT.PARAGRAPH_300_LIGHT_WEIGHT};`;
              var span = '<span style="' + style + '">';
              innerHtml += span + " " + body[0] + "</span>";
            });

            titleLines.forEach(function (title) {
              currentWeeek = title;
              var style = `font-size: ${APP_CONST.STYLE.FONT_SIZE.PARAGRAPH_300_HEAVY_SIZE}`;
              style += `;font-family: ${APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT}`;
              style += `; font-weight: ${APP_CONST.STYLE.FONT_WEIGHT.PARAGRAPH_300_HEAVY_WEIGHT}`;
              style += `;color: ${APP_CONST.STYLE.COLOR.NEUTRAL_900}`;
              style += `;line-height: ${APP_CONST.STYLE.LINE_HEIGHT.PARAGRAPH_300_HEAVY_LINE_HEIGHT}`;
              var span = '<span style="' + style + '">';
              innerHtml += span + "Date" + ":</span>";
            });

            bodyLines.forEach(function (body, i) {
              var style = `font-size: ${APP_CONST.STYLE.FONT_SIZE.PARAGRAPH_300_LIGHT_SIZE}`;
              style += `;font-family: ${APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT}`;
              style += `;color: ${APP_CONST.STYLE.COLOR.NEUTRAL_900}`;
              style += ";max-width: 50px";
              style += `;line-height: ${APP_CONST.STYLE.LINE_HEIGHT.PARAGRAPH_300_LIGHT_LINE_HEIGHT}`;
              style += `;font-weight: ${APP_CONST.STYLE.FONT_WEIGHT.PARAGRAPH_300_LIGHT_WEIGHT};`;
              var span = '<span style="' + style + '">';
              innerHtml += span + " " + formattedDate(body[1]) + "</span>";
            });

            innerHtml += "</div>";

            var tableRoot = tooltipEl.querySelector("table");
            tableRoot.innerHTML = innerHtml;
          }

          var position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          if (
            chartData.isMobileWidth &&
            Number(currentWeeek) ===
            chartData.labels[chartData.labels.length - 2]
          ) {
            tooltipEl.style.left =
              position.left +
              window.pageXOffset +
              tooltipModel.caretX -
              60 +
              "px";
          } else {
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + "px";
          }
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding =
            tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
      legend: {
        onHover: function (e) {
          e.target.style.cursor = 'pointer';
        }
      },
      hover: {
        onHover: function (e) {
          var point = this.getElementAtEvent(e);
          if (point.length) e.target.style.cursor = 'pointer';
          else e.target.style.cursor = 'default';
        }
      },
      animation: {
        duration: 1,
        onComplete: function () {
          if (this.data.printChartStatus) {
            var ctx = this.chart.ctx;
            ctx.fillStyle = "rgb(0, 0, 0)";
            var fontSize = APP_CONST.STYLE.FONT_SIZE.PARAGRAPH_400_HEAVY_SIZE;
            var fontStyle = APP_CONST.STYLE.FONT_WEIGHT.PARAGRAPH_400_HEAVY_WEIGHT;
            var fontFamily = APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT;
            ctx.font = Chart.helpers.fontString(
              fontSize,
              fontStyle,
              fontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach(function (dataset) {
              for (var i = 0; i < dataset.data.length; i++) {
                if (!dataset.data[i].fakeValue) {
                  var model =
                    dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                    scale_max =
                      dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale
                        .maxHeight;
                  ctx.fillStyle = APP_CONST.STYLE.COLOR.NEUTRAL_700;
                  var y_pos = model.y - 5;
                  if ((scale_max - model.y) / scale_max >= 0.93)
                    if (dataset.data[i] > 80) {
                      y_pos = model.y + 25;
                    } else {
                      y_pos = model.y + 25;
                    }
                  ctx.fillText(dataset.data[i].y, model.x, y_pos);
                }
              }
            });
          }

          var chartInstance = this.chart,
            ctx = chartInstance.ctx;

          var originalLineDraw = Chart.controllers.line.prototype.draw;
          Chart.helpers.extend(Chart.controllers.line.prototype, {
            draw: function () {
              originalLineDraw.apply(this, arguments);

              var chart = this.chart;
              var ctx = chart.chart.ctx;

              if (
                this.chart.tooltip._active &&
                this.chart.tooltip._active.length
              ) {
                var activePoint = this.chart.tooltip._active[0];
                var ctx = this.chart.ctx;
                var x = activePoint.tooltipPosition().x;
                var topY = this.chart.scales["y-axis-0"].top;
                var bottomY = this.chart.scales["y-axis-0"].bottom;

                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 0.5;
                ctx.strokeStyle = APP_CONST.STYLE.COLOR.NEUTRAL_900;
                ctx.stroke();
                ctx.restore();
              }
            },
          });
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              zeroLineWidth: 1,
              zeroLineColor: "black",
              drawTicks: false,
            },
            ticks: {
              fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
              fontSize: this.isMobileWidth ? 12 : 14,
              fontColor: "black",
              padding: 10,
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,

              callback: function (tick, index, ticksArray) {
                if (index % 7 != 0) {
                  return undefined;
                } else {
                  return tick;
                }
              },
            },
            scaleLabel: {
              display: true,
              labelString: "Week",
              fontSize: this.isMobileWidth ? 14 : 16,
              fontStyle: "bold",
              fontColor: "black",
              fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              display: false,
              zeroLineWidth: 1,
              zeroLineColor: "black",
            },
            ticks: {
              beginAtZero: true,
              steps: 5,
              stepValue: 1,
              stepSize: 1,
              max: 4,
              fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
              fontSize: this.isMobileWidth ? 12 : 14,
              fontColor: "black",
            },
            scaleLabel: {
              display: true,
              labelString: "Quality Scores",
              fontSize: 16,
              fontStyle: "bold",
              fontColor: "black",
              fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      annotation: {
        drawTime: "afterDatasetsDraw",
        events: ["click"],
        annotations: [
          {
            id: "hline",
            type: "line",
            mode: "horizontal",
            scaleID: "y-axis-0",
            value: 3,
            borderColor: APP_CONST.STYLE.COLOR.NEUTRAL_500,
            borderWidth: 0,
            borderDash: [7, 7],
            borderDashOffset: 5,
            label: {
              fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
              fontSize: chartData.isMobileWidth ? 9 : 10,
              fontStyle: 500,
              fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_500,
              xPadding: 0,
              yPadding: 0,
              cornerRadius: 0,
              position: "right",
              xAdjust: chartData.isMobileWidth ? 0 : 3,
              yAdjust: 14,
              enabled: true,
              backgroundColor: APP_CONST.STYLE.COLOR.NEUTRAL_100,
              content: chartData.isMobileWidth
                ? "ME: 3"
                : "Meets Expectations: 3",
            },
          },
        ],
      },
      onClick: (event, activeElements) => {
        if (activeElements.length > 0) {
          const chart = activeElements[0]._chart;
          const activePoints = chart.getElementsAtEventForMode(
            event,
            "point",
            chart.options
          );
          const firstPoint = activePoints[0];
          const value =
            chart.data.datasets[firstPoint._datasetIndex].data[
            firstPoint._index
            ];
          this.$emit("assementQualityData", value.x);
        }
      },
    };

    if (this.hideQualityAnnotation) {
      options.annotation = null;
    }
    options.scales.xAxes[0].gridLines.color = gridLineColor;
    this.renderChart(chartData, options);
  },
};
</script>