import { render, staticRenderFns } from "./DataDashboardSidepanel.vue?vue&type=template&id=5081652a&scoped=true&"
import script from "./DataDashboardSidepanel.vue?vue&type=script&lang=ts&"
export * from "./DataDashboardSidepanel.vue?vue&type=script&lang=ts&"
import style0 from "./fileUpload.less?vue&type=style&index=0&id=5081652a&lang=less&scoped=true&"
import style1 from "./DataDashboardSidepanel.less?vue&type=style&index=1&id=5081652a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5081652a",
  null
  
)

export default component.exports